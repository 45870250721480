function Screen(props) {

    return (
        <div className="screen" id="screen">
            <div className="message from">
                <p className="from-me">
                    Hello.
                </p>
            </div>
            <div className="message from">
                <p className="from-me last" id="nameBlock">
                    I'm Sawyer. 
                </p>
            </div>
        </div>
    );
}

export default Screen;