import Ball from "./Ball";
import Paddle from "./Paddle";
import resume2023 from "./resume2023.pdf";

let lastTime;
let pause = false; 

function TerminalBody(props) {

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            processInput(e.target.value);
            e.target.value = "";
        }
    };

    return (
        <div className="terminalBody" id="terminalBody">
            <div className="terminalInput" id="input">{'>'}root@sawyerfenwick.dev ~ $
            <input 
                className="terminalInputTag" 
                type="text" 
                id="terminalInput" 
                placeholder="enter command" 
                size="50" 
                name="terminalInput" 
                onKeyDown={handleKeyDown}
                autoFocus/>
            </div>
            <div className="terminalOutput" id="output"></div>
        </div>
    );
}

function processInput(text) {
    switch (text) {
        case "help":
            help();
            break;
        case "about":
            about();
            break;
        case "resume":
            resume();
            break;
        case "linkedin":
            linkedin();
            break;
        case "github":
            github();
            break;
        case "contact":
            contact();            
            break;
        case "pong":
            pong();
            break;
        case "clear":
            clearScreen();
            break;
        case "skills":
            skills();
            break;
        default:
            errorInput();
    }
}

function errorInput() {
    document.getElementById("output").innerHTML = 
    "\nError: Unrecogonized command. Please use a command from the bottom of the page.";
}

function clearScreen() {
    document.getElementById("output").innerHTML = "";
}

function help() {
    document.getElementById("output").innerHTML = 
    "\nThere is a list of commands at the bottom of the page. Choose one, type it in the \"enter command\" field and press Enter.\n\nWhy am I even telling you this? You clearly figured this out already, I mean you're reading this right now aren't you?";
}

function about() {
    let aboutme = 
    "<p>\nHello there.\n\nMy name is Sawyer Fenwick. I am an aspiring Software Developer, currently residing in Niagara, Ontario, Canada.\n\nEver since I was young, I have had a passion for problem solving, puzzles, "
    +"mathematics, technology and learning.\n\nThese interests pushed me to take my first computer classes in high school, where I began learning Java and python.\n\nThose computer classes led me to pursue a career "
    +"in Software Development.\n\nI have just recently completed the first step in this journey, aquiring a BSc. in Computer Science and Mathematics which I received from Brock University in 2022.\n\nWhile in "
    +"school, I was working at Tim Horton's as a restaurant manager.\n\nThis job allowed me to develop a host of soft skills that I use to this day, both professionally and personally.\n\nI am now looking for Junior " 
    +"Developer jobs so I can begin to grow my career. I am interested in any kind of Software work, from Full Stack to Dev/Ops and anything in between and beyond.\n\nI am looking for experience, and mentorship.\n\n"
    +"Outside of software, some of my interests include:\n\nF1: I'm a McLaren / Norris fan, but I was rooting for Ferrari last season. I don't really want to talk about it.\n\nHockey: The Leafs are my hometown team but " 
    +"I cheer for the Wings. I play ball hockey twice a week.\n\nGolf: I play (Triple Bogey)\n\nComics: I am currently reading Ryan North's Fantastic Four, Zeb Wells ASM, and Chip Zdarsky's DareDevil.\n\n"
    +"Music: I play Piano, Guitar and Trumpet. Here's a <a href=https://open.spotify.com/playlist/37i9dQZF1F0sijgNaJdgit?si=8599b674240e4723>link</a> to my Spotify Top 2022</p>" 
    document.getElementById("output").innerHTML = aboutme;
}

function resume() {
    window.open(resume2023);
}

function linkedin() {
    window.open("https://www.linkedin.com/in/sawyerfenwick/");
    document.getElementById("output").innerHTML = "\n<p>Yes, that is me and bassist / co-lead singer of Kiss, Gene \"The Demon\" Simmons.\n\nIf you want to hear about it, you'll have to call me.\n\nIf you don't want to hear about it, still call me. We can talk about anything else.</p>";
}

function github() {
    window.open("https://github.com/sawyerfenwick");
}

function contact() {
    let contactme = "\nSend me a message via text/e-mail, or call me on my phone!\n\ncell: 289.922.9743 \ne-mail: sawyerfenwick@gmail.com";
    document.getElementById("output").innerHTML = contactme;
}

function skills() {
    let skills = "\nJava, Kotlin, Swift, Python, C, C++, VB.NET, SQL, React, Express.js, MongoDB, Node.js\n\nTime Management, Communication, Teamwork, Leadership, Conflict Resolution\n";
    let courses = "\nMajor Courses: \nAlgorithms, Computer Hardware, Operating Systems, Theory of Computation, Computer Networking, Database Management Systems, Functional Programming in Haskell, Data Structures, Software Engineering, Calculus, Linear Algebra, Statistics, Probability"
    document.getElementById("output").innerHTML = skills+courses;
}

function pong() {
    document.getElementById("output").remove();
    document.getElementById("input").remove();
    setup();
    play();
}

function play() {
    let terminalBody = document.getElementById("terminalBody");
    let ball = new Ball(document.getElementById("ball"));
    const playerPaddle = new Paddle(document.getElementById("player-paddle"));
    const computerPaddle = new Paddle(document.getElementById("computer-paddle"));
    const playerScoreElem = document.getElementById("player-score");
    const computerScoreElem = document.getElementById("computer-score");

    const keyPress = (e) => {
        const rect = playerPaddle.rect();
        const bodyRect = terminalBody.getBoundingClientRect();
        
        if (e.key === "p" || e.key === "P") {
            pause = false;
            lastTime = null;
            document.getElementById("playString").remove();
            update();
        }

        if (e.key === "q" || e.key === "Q") {
            pause = true;
            document.removeEventListener("keydown", keyPress);
            let playString = document.getElementById("playString");
            if (playString != null) {
                playString.remove();
            }
            reset();
        }

        if (e.key === "ArrowUp" && rect.top >= bodyRect.top) {
            playerPaddle.position -= 5
        }
        else if (e.key === "ArrowDown" && rect.bottom <= bodyRect.bottom) {
            playerPaddle.position += 5
        }
    }

    document.addEventListener("keydown", keyPress);

    function update(time) {
        if (pause) return;
        console.log("time: " + time);
        if (lastTime != null) {
            const delta = time - lastTime;
            ball.update(delta, [playerPaddle.rect(), computerPaddle.rect()]);
            computerPaddle.update(delta, ball.y);

            if (isLose()) {
                handleLose();
                ball.reset();
            }
        }

        lastTime = time;

        window.requestAnimationFrame(update);
    }

    function handleLose() {
        const rect = ball.rect() 
        if (rect.right >= window.innerWidth) {
            playerScoreElem.textContent = parseInt(playerScoreElem.textContent) + 1
        }
        else {
            computerScoreElem.textContent = parseInt(computerScoreElem.textContent) + 1
        }
    }
    
    function isLose() {
        const rect = ball.rect()
        return rect.right >= window.innerWidth || rect.left <= 0
    }

}

function setup() {
    let body = document.getElementById("terminalBody");
    let score = document.createElement("div");
    let playerscore = document.createElement("div");
    let compscore = document.createElement("div");
    let ball = document.createElement("div");
    let lPaddle = document.createElement("div");
    let rPaddle = document.createElement("div");
    let playString = document.createElement("div");

    playString.innerHTML = "\"P\" to Play\n\"Q\" to Quit";
    playString.className = "playString";
    playString.id = "playString";

    score.className = "score";
    score.id = "score"

    playerscore.innerHTML = "0";
    playerscore.id = "player-score";
    compscore.innerHTML = "0";
    compscore.id = "computer-score";

    score.appendChild(playerscore);
    score.appendChild(compscore);

    body.appendChild(score);

    ball.className = "ball";
    ball.id = "ball";

    body.appendChild(ball);
    
    lPaddle.className = "paddle left";
    lPaddle.id = "player-paddle";

    rPaddle.className = "paddle right";
    rPaddle.id = "computer-paddle";

    body.appendChild(lPaddle);
    body.appendChild(rPaddle);

    body.append(playString);
}

function reset() {
    
    document.getElementById("score").remove();
    document.getElementById("ball").remove();
    document.getElementById("player-paddle").remove();
    document.getElementById("computer-paddle").remove();

    let body = document.getElementById("terminalBody");
    let input = document.createElement("div");
    let output = document.createElement("div");

    input.id = "input";
    input.className = "terminalInput";

    output.id = "output";
    output.className = "terminalOutput";

    let inputText = document.createTextNode(">root@sawyerfenwick.dev ~ $");
    input.appendChild(inputText);

    let inputBox = document.createElement("input");
    inputBox.setAttribute("type", "text");
    inputBox.onkeydown = (e) => {
        if (e.key === "Enter") {
            processInput(e.target.value);
            e.target.value = "";
        }
    };
    inputBox.className = "terminalInputTag";
    inputBox.setAttribute("id", "terminalInput");
    inputBox.setAttribute("placeholder", "enter command");
    inputBox.setAttribute("size", "50");
    inputBox.setAttribute("autoFocus", "true");

    input.appendChild(inputBox);

    body.appendChild(input);
    body.appendChild(output);
    
}

export default TerminalBody;