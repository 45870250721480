function Commands(props) {

    return (
        <div className="commands">
            <p>Commands:</p>
            <p>help clear about skills resume linkedin github contact pong</p>
        </div>
        
    );
}

export default Commands;  