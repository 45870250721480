import TerminalBody from './TerminalBody'

function Terminal(props) {
    return (
        <div>
            <div className="terminalHeader">
                <span className="circle red"></span>
                <span className="circle yellow"></span>
                <span className="circle green"></span>    
            </div>
            <TerminalBody/>
        </div>
    );
}

export default Terminal;