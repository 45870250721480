import resume2023 from './resume2023.pdf'

let firstMessageReceived = false;
let lastSent = null; 
let lastReceived = null;

function Footer(props) {

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
           parseInput(e.target.value);
           e.target.value = "";
        }
    };

    function parseInput(userInput) {
        let screen = document.getElementById("screen");
        if(userInput !== "") {
            send(userInput);
        }
        screen.scrollTop = screen.scrollHeight;
        document.getElementById("dotblock").style.visibility = "visible";
        setTimeout(function () {respond(userInput)}, 2500);
    }
    
    function respond(userInput) {
        let screen = document.getElementById("screen");
        
        let responseDiv = document.createElement("div");
        responseDiv.className = "message from";

        let responseText = document.createElement("p");
        responseText.className = "from-me last";

        if (!firstMessageReceived) {
            firstMessageReceived = true;
            document.getElementById("nameBlock").style.borderRadius = "25px";
        }
        else {
            lastReceived.style.borderRadius = "25px";    
        }

        lastReceived = responseText;
        document.getElementById("dotblock").style.visibility = "hidden";
        switch(userInput) {
            case "about": case "About": case "ABOUT":
                responseText.innerHTML = about();
                break;
            case "resume": case "Resume": case "RESUME":
                window.open(resume2023);
                break;
            case "linkedin": case "Linkedin": case "LINKEDIN":
                responseText.innerHTML = linkedin();
                break;
            case "github": case "Github": case "GITHUB":
                responseText.innerHTML = github();
                break;
            case "email": case"e-mail": case "EMAIL": case "Email": case "E-mail": case "E-MAIL":
                responseText.innerHTML = email();
                break;
            default:
                responseText.innerHTML = errorResponse();
        }
        responseDiv.appendChild(responseText);
        screen.appendChild(responseDiv);
        screen.scrollTop = screen.scrollHeight;
    }

    function send(userInput) {
        let screen = document.getElementById("screen");
        let userQueryDiv = document.createElement("div");
        userQueryDiv.className = "message to";
        
        let userQueryText = document.createElement("p");
        
        if (lastSent === null) {
            lastSent = userQueryText;
        }
        else {
            lastSent.style.borderRadius = "25px";
            lastSent = userQueryText;
        }
        userQueryText.className = "to-me last";
        userQueryText.innerHTML = userInput;
    
        userQueryDiv.appendChild(userQueryText);
    
        screen.appendChild(userQueryDiv);
    }
    
    function about() {
        
        return(
        "I am an aspiring Software Developer, currently residing in Niagara, Ontario, Canada.\n"
        +"I have just recently completed the first step in this journey, aquiring a BSc. in Computer Science and Mathematics which I received from Brock University in 2022.\n"
        +"I am now looking for Junior Developer jobs so I can begin to grow my career. I am interested in any kind of Software work, from Full Stack to Dev/Ops and anything in between and beyond."
        );
    }

    function email() {
        return("Shoot me an email at sawyerfenwick@gmail.com");
    }

    function linkedin() {
        return("Before you ask, yes. That is a picture of me and Gene Simmons. This <a href=\"https://www.linkedin.com/in/sawyerfenwick/\">link</a> will take you to my LinkedIn page.");
    }
    
    function github() {
        return("This <a href=\"https://github.com/sawyerfenwick\">link</a> will take you to my Github page.");
    }
    
    function errorResponse() {
        return("Sorry, you're not actually talking to me right now. It's just a switch statement. You'll have to use a command.");
    }

    return (
        <div className="footer">
            <div className="dotBlock" id="dotblock">
                <div className="dot"></div>
                <div className="dot two"></div>
                <div className="dot three"></div>
            </div>
            <input 
                className="inputBox" 
                type="text"
                id="inputBox"
                placeholder="Message"
                onKeyDown={handleKeyDown}    
            />
        </div>
    );
}

export default Footer;