import './App.css';
import {useState,useEffect} from 'react';
import axios from 'axios';
import Welcome from './components/Welcome';
import Terminal from './components/Terminal';
import Commands from './components/Commands';
import Header from './components/Header';
import Screen from './components/Screen';
import Footer from './components/Footer';
import { isMobile } from 'react-device-detect';

function App() {
  //creating IP state
  const [ip, setIP] = useState('');

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://ipapi.co/ip');
    console.log(res.data);
    setIP(res.data);
  }

  

  useEffect( () => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  if (isMobile) {
    return (
      <div className="App">
        <div className='mobileContainer'>
          <Header/>
          <Screen/>
          <Footer/>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="App">
        <Welcome ip={ip} />
        <Terminal/>
        <Commands/>
      </div>
    );
  }
}

export default App;
