import profilePic from './profile.PNG';
import info from './info.png';

let infoVisible = false;

function Header(props) {

    return (
        <div className='header'>
            <img src={profilePic} className="profile-pic" alt='profile_picture'/> 
            <div className='nameContainer'>
                <p className="name">Sawyer Fenwick</p>
                <div className="active">
                    <div className='activeCircle'/>
                    <p className='activeText'>Active Now</p>
                </div>
            </div>
            <img src={info} className = "info-pic" alt="info.png" onClick={infoClick}/>
            <div className='infoBlock' id="infoBlock">
                <p className='infoText'>Commands:</p>
                <p className='infoText'>about</p>
                {/* <p className='infoText'>resume</p> */}
                <p className='infoText'>email</p>
                <p className='infoText'>github</p>
                <p className='infoText'>linkedin</p>
            </div>
        </div>
    );
}

function infoClick() {
    if (infoVisible) {
        infoVisible = false;
        document.getElementById("infoBlock").style.visibility = "hidden";
    }
    else {
        infoVisible = true;
        document.getElementById("infoBlock").style.visibility = "visible";
    }
}

export default Header;